import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { draPartnerId, englishStarCourseId, env, partnerId } from "api/Consts";
import EnglishStarAdvertisement from "./dashboardcarousel/EnglishStarAdvertisement";
import PurchaseDemoedCourse from "./dashboardcarousel/PurchaseDemoedCourse";
import { NextArrow, PrevArrow } from "./dashboardcarousel/Arrows";
import courseSvc from "services/Course";
import { GET_COUPONS_CODE } from "../../graphql/MycourseQuery/MyCourse";
import { useQuery } from "@apollo/client";
const SLIDE_TYPES = {
  purchase_demoed_course: "purchase-demoed-course",
  english_star_ad: "english-star-ad",
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export default function SimpleSlider(props) {
  const [attendedDemos, activeCourses] = useSelector(({ course }) => [
    course.user_demo_batches,
    course.active_courses,
  ]);

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (attendedDemos)
      (async () => {
        const slides = [];

        let unpurchased = {},
          isEnglishDemoed = false,
          isEnglishPurchased = !!activeCourses[englishStarCourseId];

        // Courses that have been demoed but not purchased
        attendedDemos
          .filter((bs) => new Date() > new Date(bs.endto_date))
          .forEach((batch_slot) => {
            if (batch_slot.batch.course_id === englishStarCourseId)
              isEnglishDemoed = true;

            const purchasedCourse = activeCourses[batch_slot.batch.course_id];
            if (!purchasedCourse)
              unpurchased[batch_slot.batch.course_id] = true;
          });

        const unpurchasedCoursesFullDetails = await courseSvc.getCoursesById(
          Object.keys(unpurchased)
        );

        Object.keys(unpurchased).map((courseId) => {
          const course = unpurchasedCoursesFullDetails.find(
            (c) => c.id == courseId
          );
          slides.push({
            type: SLIDE_TYPES.purchase_demoed_course,
            course,
          });
        });

        // If english has not been demoed, advertise it
        if (!isEnglishDemoed && !isEnglishPurchased)
          slides.push({ type: SLIDE_TYPES.english_star_ad });

        setSlides(slides);
      })();
  }, [attendedDemos, activeCourses]);

  return (
    <div className="rounded-md p-6 mt-4 w-full">
      {/* <h3 className="font-poppins font-bold text-2xl text-japanese_indigo my-4">
        Advertised Courses
      </h3> */}

      {partnerId !== draPartnerId && (
        <Slider {...settings}>
          {/* {couponIds &&
          couponIds.map((data) => {
            return <div>{data.user_id}</div>;
          })} */}
          {slides.map((slide) => {
            if (slide.type === SLIDE_TYPES.purchase_demoed_course) {
              return <PurchaseDemoedCourse {...slide} />;
            } else if (slide.type === SLIDE_TYPES.english_star_ad)
              return <EnglishStarAdvertisement />;
          })}
        </Slider>
      )}
    </div>
  );
}

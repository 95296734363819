import Marquee from "react-fast-marquee";
import partnerData from "../../partnerData.json";

export default function PartnerMarquee() {
  return (
    <>
      <div className="w-full lg:w-4/5 mx-auto mt-8">
        <h1 className="mx-4 lg:mx-0 text-xl font-semibold">
          {partnerData?.partnerDisplayText}
        </h1>
        <h1 className="mx-4 lg:mx-0 text-xl font-semibold">Partners</h1>
        <div className="mx-4 lg:mx-0 w-12 border-b-2 border-orange"></div>
        <div className="mt-8 flex items-center">
          <Marquee speed={60}>
            {[
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner1.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner2.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner3.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner4.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner5.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner6.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner7.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner8.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner9.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner10.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner11.png"
              />,
            ].map((i) => {
              return i;
            })}
          </Marquee>
        </div>

        <div className="mt-6 flex items-center">
          <Marquee speed={50}>
            {[
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner11.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner12.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner13.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner14.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner15.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner16.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner17.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner18.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner19.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner20.png"
              />,
              <img
                style={{ flex: "none" }}
                className="mr-20"
                alt="partner"
                src="./partner21.png"
              />,
            ].map((i) => {
              return i;
            })}
          </Marquee>
        </div>
      </div>
    </>
  );
}

/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Logout } from "../../redux/actions/Auth";

import { RiDashboardFill, RiUserSettingsFill } from "react-icons/ri";
import { ImBooks } from "react-icons/im";
import { GiSuitcase } from "react-icons/gi";
import { FaUserGraduate, FaUserFriends } from "react-icons/fa";
import { RiCoupon2Fill } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";

import Logo from "assets/images/logo.svg";
import { draPartnerId, marketingHost, partnerId } from "api/Consts";
import CourseSearchBox from "components/CourseSearchBox";
import { GrCertificate } from "react-icons/gr";
import draLogo from "../../assets/images/draLogo.png";

export const contactSupport = () => alert("Support contact requested");

export const options = (ctx) => {
  const mainMenu = [
    {
      component: Link,
      to: "/",
      label: "My Dashboard",
      icon: <RiDashboardFill size={20} />,
    },
    {
      component: Link,
      to: "/courses",
      label: "Browse Courses",
      icon: <ImBooks size={20} />,
    },
    {
      component: Link,
      to: "/myCertificates",
      label: "My Certificates",
      icon: <GrCertificate size={20} />,
    },
    {
      component: Link,
      to: "/jobs",
      label: "Jobs",
      icon: <GiSuitcase size={22} />,
    },
    {
      component: Link,
      to: "/allscholarships",
      label: "Scholarships",
      icon: <FaUserGraduate size={20} />,
    },
    {
      component: Link,
      to: "/coupons",
      label: "SkillsTrainer Coupon",
      icon: <RiCoupon2Fill size={20} />,
    },
    {
      component: Link,
      to: "/settings/account-details",
      label: "My Profile",
      icon: <RiUserSettingsFill size={20} />,
    },
    {
      component: Link,
      to: "/referral",
      label: "Refer A Friend",
      icon: <FaUserFriends size={20} />,
    },
  ];

  const draMainMenu = [
    {
      component: Link,
      to: "/",
      label: "My Dashboard",
      icon: <RiDashboardFill size={20} />,
    },
    {
      component: Link,
      to: "/myCertificates",
      label: "My Certificates",
      icon: <GrCertificate size={20} />,
    },
    {
      component: Link,
      to: "/settings/account-details",
      label: "My Profile",
      icon: <RiUserSettingsFill size={20} />,
    },
  ];

  // Use appropriate menu based on partnerId
  const mainMenuOptions = partnerId === draPartnerId ? draMainMenu : mainMenu;
  console.log(mainMenuOptions);

  return {
    main: !ctx.isLoggedIn
      ? [
          ...(draPartnerId !== partnerId
            ? [
                {
                  component: "a",
                  target: "_blank",
                  link: `${marketingHost}`,
                  label: "Home",
                },
                {
                  component: "a",
                  target: "_blank",
                  link: `${marketingHost}/about`,
                  label: "About us",
                },
                {
                  component: "a",
                  target: "_blank",
                  link: `${marketingHost}/courses`,
                  label: "Courses",
                },
                {
                  component: "a",
                  target: "_blank",
                  link: `${marketingHost}/blogs`,
                  label: "Blogs",
                },
                {
                  component: "a",
                  target: "_blank",
                  link: `${marketingHost}/privacy`,
                  label: "Privacy policies",
                },
              ]
            : []),
        ]
      : mainMenuOptions,
    side: !ctx.isLoggedIn
      ? partnerId !== draPartnerId
        ? [
            { component: Link, to: "/enterprise", label: "Work Partner" },
            { component: Link, to: "/login", label: "Login" },
            { component: Link, to: "/signup", label: "Join for Free" },
          ]
        : [
            {
              component: "button",
              label: "Contact Support",
              onClick: contactSupport,
            },
          ]
      : [
          { component: Link, to: "/settings", label: "Settings" },
          ...(draPartnerId !== partnerId
            ? [
                { component: Link, to: "/orders", label: "Orders" },
                { component: Link, to: "/subscription", label: "Subscription" },
              ]
            : [
                {
                  component: "button",
                  label: "Contact Support",
                  onClick: contactSupport,
                },
              ]),
          {
            component: "button",
            label: "Logout",
            onClick: () => ctx.dispatch(Logout()),
          },
        ],
  };
};

export default function Navbar() {
  const auth = useSelector(({ auth }) => auth);
  const user = auth.user?.db_user;
  const isLoggedIn = auth.authenticated;
  const dispatch = useDispatch();
  const newOptions = options({ dispatch, isLoggedIn });
  const loaction = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [navDropdownClicked, setNavDropdownClicked] = useState(false);
  const location = useLocation();
  const quiz = useSelector(({ quiz }) => quiz);

  const courseViewPath = matchPath(loaction.pathname, {
    path: `/course/view/:id`,
  });

  const courseViewTopicPath = matchPath(loaction.pathname, {
    path: `/course/view/:id/lesson/:id`,
  });

  return (
    quiz.QuizMode &&
    !quiz.ScormFullScreen && (
      <>
        {/* <div className="bg-jungle_green filter blur-2xl w-7 h-64 absolute mt-64" /> */}
        <div className="sticky top-0" style={{ zIndex: "2" }}>
          <div className="w-full mx-auto px-4 sm:px-6 bg-white shadow-xl rounded-md">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-4 md:space-x-10">
              <Link
                className={`flex justify-start lg:mr-5 cursor-pointer ${
                  courseViewPath && courseViewTopicPath
                    ? `font-bold p-2 hover:text-white hover:bg-${
                        partnerId || "default"
                      }-primary`
                    : ""
                }`}
                to="/"
                rel="noreferrer noopener"
              >
                {courseViewPath && courseViewTopicPath ? (
                  "<< Back To Dashboard"
                ) : (
                  <div>
                    <span className="sr-only">Workflow</span>
                    {partnerId === draPartnerId ? (
                      <img
                        className="h-8 w-auto sm:h-10"
                        src={draLogo}
                        alt=""
                      />
                    ) : (
                      <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
                    )}
                  </div>
                )}
              </Link>

              {/* Desktop Options */}
              {!isLoggedIn && (
                <nav className="hidden lg:flex space-x-10">
                  {newOptions.main.map((option) => (
                    <option.component
                      to={option.to}
                      href={option.link}
                      target={option.target}
                      onClick={option.onClick}
                      className="text-base text-gray-500 hover:text-gray-900 my-auto"
                    >
                      {option.label}
                    </option.component>
                  ))}

                  {/* <div className="relative bg-orange flex flex-col items-center justify-center rounded-md py-1 px-2">
                  <span className="-top-4 -right-4 absolute animate-pulse bg-orange-light text-japanese_indigo rounded-full p-1 text-xs font-semibold shadow-lg">New!</span>
                  <Link to="/giftcourse">
                    <p className="text-white font-semibold">Gift a course!</p>
                  </Link>
                </div> */}
                </nav>
              )}

              {isLoggedIn &&
                location.pathname !== "/allscholarships" &&
                location.pathname !== "/courses" &&
                !courseViewPath &&
                !courseViewTopicPath && <CourseSearchBox />}

              {isLoggedIn ? (
                <div
                  onClick={() => setNavDropdownClicked(!navDropdownClicked)}
                  onMouseEnter={() => setNavDropdownClicked(true)}
                  className="hidden lg:flex items-center space-x-1"
                >
                  <button className="rounded-md cursor-pointer hover:shadow-md">
                    <img
                      className="mx-2 p-3"
                      alt="notifs"
                      src="/notification.png"
                    />
                  </button>
                  <div className="flex flex-row items-center p-2 rounded-md cursor-pointer hover:shadow-md">
                    <p className="text-gray-500 text-lg font-medium mx-2 font-poppins hover:text-blue-500 transition duration-300">
                      Hi, {user?.name.split(" ")[0]}
                    </p>
                    <div className="flex flex-row text-gray-500 font-poppins transition duration-300">
                      <BiChevronDown
                        className={`text-${
                          partnerId || "default"
                        }-primary my-auto`}
                        size={20}
                      />
                      <img
                        alt="user-img"
                        src="/profile.png"
                        className="w-10 h-10 my-auto"
                      />
                    </div>
                  </div>

                  {navDropdownClicked && (
                    <div
                      onMouseLeave={() => setNavDropdownClicked(false)}
                      className="absolute top-16 right-16 bg-white text-base z-50 flex flex-col rounded-md my-4"
                    >
                      {newOptions.side.map((option) => (
                        <option.component
                          to={option.to}
                          onClick={option.onClick}
                          href={option.link}
                          target={option.target}
                          className={`px-16 py-4 w-full text-center text-base font-medium text-${
                            partnerId || "default"
                          }-primary hover:text-${
                            partnerId || "default"
                          }-primary`}
                        >
                          {option.label}
                        </option.component>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex items-center">
                  {newOptions.side.map((option) => (
                    <option.component
                      to={option.to}
                      onClick={option.onClick}
                      title={
                        option.label === "Work Partner"
                          ? "For Enterprises, Educational Institutes, NGOs, Government"
                          : ""
                      }
                      href={option.link}
                      target={option.target}
                      className={`hidden lg:block ${
                        option.label === "Join for Free"
                          ? `text-white bg-${
                              partnerId || "default"
                            }-primary rounded-xl p-2`
                          : `px-3 text-sm lg:text-base text-${
                              partnerId || "default"
                            }-primary hover:text-${
                              partnerId || "default"
                            }-primary`
                      }`}
                    >
                      {option.label === "Work Partner" &&
                      location.pathname === "/enterprise"
                        ? ""
                        : option.label}
                    </option.component>
                  ))}
                </div>
              )}

              {/* Mobile options */}
              <div className="-mr-2 -my-2 lg:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="mobile-menu-button bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-expanded="false"
                  onClick={() => setIsOpen(true)}
                >
                  {/* <span className="sr-only">Open menu</span> */}

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`lg:hidden fixed top-0 right-0 h-screen p-5 flex flex-col z-20 bg-${
                  partnerId || "default"
                }-primary`}
                style={{
                  transform: `translateX(${isOpen ? "0%" : "100%"})`,
                  transition: "0.2s",
                  color: "#fff",
                  zIndex: "10",
                }}
              >
                <div className="flex justify-end">
                  <div className="w-max" onClick={() => setIsOpen(false)}>
                    <box-icon
                      name="x"
                      size="sm"
                      color={partnerId === draPartnerId ? "white" : "black"}
                    />
                  </div>
                </div>
                <ul className="mt-10">
                  {newOptions.main.concat(newOptions.side).map((option) => (
                    <li
                      className="text-xl my-3"
                      style={{ width: "10rem" }}
                      onClick={() => setIsOpen(false)}
                    >
                      <option.component
                        to={option.to}
                        onClick={option.onClick}
                        href={option.link}
                        target={option.target}
                        className="text-left"
                      >
                        {option.label}
                      </option.component>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

import { gql } from "@apollo/client";

export const GET_USER_BY_IDP_USER_ID = gql`
  query fetchUser($idp_user_name: String) {
    courses_users(where: { idp_user_name: { _eq: $idp_user_name } }) {
      source
    }
  }
`;
export const GET_USER_BATCH_COURSE_BY_UID = gql`
  query getuserbatchcoursebyuid($user_id: Int) {
    courses_user_batch_enrollment(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: asc }
    ) {
      id
      course_id
      batch_id
      batch {
        institute {
          id
          name
          pincode
          state
          district
          country
          city_town
        }
      }
      Batch_slots(order_by: { slot_date: asc }) {
        id
        slot_date
        endto_date
        type
        meeting_link
      }
    }
  }
`;

import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";

import { useLocation } from "react-router";

import liveClassService from "services/liveClass";
import { useSelector, useDispatch } from "react-redux";
import { FetchCourseLesson } from "redux/actions/Course";

import Container from "components/Container";

import { domain, partnerId, scormServerUri } from "api/Consts";
import { GetUserCourseProgress } from "api/Course";
import { useWindowEventListener } from "utils/hooks";
import CameraObjectDetection from "./CameraObjectDetection";
import QuizToast from "./QuizTemplate/QuizToast";
import courseSvc from "services/Course";
import ExpandedLoader from "components/ExpandedLoader";
import Axios from "api/Api";
import Toast from "wrappers/Toast";

/*

  NOTE: There has been a change in the scorm data submission implementation.
  
  1. SCORM player now uses the parent window's handleScormMessage() function to handle it's progress
  2. ASDM courses collect user response until the test is completed and send it to the /submitscormdata API
  thereafter, while other courses do not have any change in that respect.

*/

let eventHandlerUpdateCount = 0;

export default function ScormView(props) {
  const dispatch = useDispatch();

  const { maxattempt, course_id, description, require_webcam } = props.modData;

  const location = useLocation();
  const auth = useSelector(({ auth }) => auth);
  const quiz = useSelector(({ quiz }) => quiz);

  const userId = auth.user?.db_user?.id;
  const User = auth.user?.db_user?.name;
  const [enterScorm, setEnterScorm] = useState(false);

  const [totalAttempt, setTotalAttempt] = useState(0);

  const [newAttempt, SetNewAttempt] = useState({
    attempt_id: null,
    new: false,
  });

  const [scormParams, setScormParams] = useState({});
  const [courseProgress, setCourseProgress] = useState("");
  const [tabChangeCount, setTabChangeCount] = useState(0);
  const [webCamLoaded, SetwebCamLoaded] = useState(false);

  // New SCORM submit implementation
  const [scormPayload, setScormPayload] = useState({});

  useEffect(() => {
    initialFunction();
  }, [props.modData]);

  const initialFunction = useCallback(async () => {
    setEnterScorm(false);

    if (props.modData) {
      const userAttempt = await liveClassService.getUserModuleAttempt(
        props,
        userId
      );

      //

      // Count finished attempt
      const finishedAttempt =
        userAttempt &&
        userAttempt.filter((data) => {
          return data.attempt_completed;
        });

      setTotalAttempt(finishedAttempt.length);

      //Check current attempt is new or old
      const filterAttempt =
        userAttempt &&
        userAttempt.filter((data) => {
          return !data.attempt_completed;
        });

      if (filterAttempt.length > 0) {
        SetNewAttempt({
          attempt_id: filterAttempt[0].id,
          new: false,
        });
      } else {
        SetNewAttempt({
          attempt_id: null,
          new: true,
        });
      }
    }

    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) {
      return false;
    } else {
      md.enumerateDevices().then((devices) => {
        const detectStatus = devices.some(
          (device) => "videoinput" === device.kind
        );

        console.log("detectStatus", detectStatus);
      });
    }
  }, []);

  const startNow = async () => {
    if (newAttempt.new) {
      const attemptData = await liveClassService.InsertUSerAttempt(
        props,
        userId,
        totalAttempt
      );

      setScormParams({
        attempt_id: attemptData.id,
        mapping_id: props.modData.mapping_id,
      });

      setEnterScorm(true);
    } else {
      setScormParams({
        attempt_id: newAttempt.attempt_id,
        mapping_id: props.modData.mapping_id,
      });

      setEnterScorm(true);
    }
  };

  const submitScormData = useCallback(
    (scormPayload) =>
      Axios.post("/submitscormdata", scormPayload).then(({ data }) => {
        return data;
      }),
    []
  );

  const submitCurrentAttempt = useCallback(async () => {
    if ([178, 205].includes(course_id)) {
      // Submitting scorm data if current course is an ASDM course
      const submitScormResult = await submitScormData(scormPayload);
      console.log("Scorm Submission result", submitScormResult);
    }

    setEnterScorm(false);
    initialFunction();
    setTabChangeCount(0);
  }, [scormPayload, initialFunction, userId, course_id]);

  // New SCORM payload handling implementation
  useWindowEventListener(
    "message",
    async (event) => {
      const payload =
        event.data?.source === "scorm-viewer" && event.data.payload;
      if (!payload) return;

      // Replacing current payload object with new without changing reference
      Object.keys(scormPayload).forEach((key) => {
        delete scormPayload[key];
      });
      Object.assign(scormPayload, payload);

      setScormPayload({ ...payload });

      // Submit scorm data if not an ASDM course
      if (![178, 205].includes(course_id)) {
        console.log("Submitting SCORM data directly");
        submitScormData(payload);
      }

      if (
        ["passed", "failed"].includes(payload.scormUpdate.core.lesson_status)
      ) {
        const loader = Toast.load("Submitting attempt. Please wait...");
        await submitCurrentAttempt();
        Toast.endLoader(loader);
        dispatch(FetchCourseLesson(course_id, userId, true));
      }
    },
    [scormPayload, submitCurrentAttempt, userId, course_id]
  );

  // Detect tab or window change function if webcam required
  useEffect(() => {
    if (require_webcam) {
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          // User switched to a different tab or application
          if (tabChangeCount === 3) {
            QuizToast.error("Sorry you reached all the limits");

            submitCurrentAttempt();
          } else {
            QuizToast.error("Please Avoid To Change Tab or Window");
            setTabChangeCount(tabChangeCount + 1);
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [tabChangeCount, submitCurrentAttempt]);

  const webCamLoadedCall = () => {
    SetwebCamLoaded(true);
  };

  const scormUri = useMemo(() => {
    if ([178, 205].includes(course_id)) {
      return `${scormServerUri}/render_scorm?module_mapping_id=${scormParams.mapping_id}&attempt_id=${scormParams.attempt_id}&user_id=${userId}`;
    } else {
      return `${domain}/api/renderscorm?module_mapping_id=${scormParams.mapping_id}&attempt_id=${scormParams.attempt_id}&user_id=${userId}`;
    }
  }, [scormParams.mapping_id, scormParams.attempt_id, userId, course_id]);

  return (
    <Container>
      {enterScorm ? (
        <div id="scormIframe" style={{ position: "relative" }}>
          {require_webcam && !webCamLoaded ? (
            <ExpandedLoader />
          ) : (
            <iframe
              src={scormUri}
              height="650"
              width="100%"
              allowFullScreen={true}
              frameborder="0"
            />
          )}

          {require_webcam && (
            <CameraObjectDetection
              submitQuiz={submitCurrentAttempt}
              webCamLoaded={webCamLoadedCall}
            />
          )}
        </div>
      ) : (
        <div className="w-full text-center">
          <h2 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
            {props.modData.lessonName}
          </h2>

          {description && (
            <div className="text-3xl mb-6">
              <span className="font-medium mr-3 text-japanese_indigo">
                Description:
              </span>
              <span>{description}</span>
            </div>
          )}

          <div className="text-3xl mb-6">
            <span className="font-medium mr-3 text-japanese_indigo">
              Number of attempt Allowed:{" "}
            </span>
            <span>
              {maxattempt == 0 ? "Unlimited" : maxattempt + " Attempts"}
            </span>
          </div>
          <div className="text-3xl mb-6">
            <span className="font-medium mr-3 text-japanese_indigo">
              Number of attempt you have made:
            </span>
            <span>{totalAttempt ? totalAttempt : 0}</span>
          </div>

          {totalAttempt >= maxattempt && maxattempt !== 0 ? (
            <div className="mt-4">
              <span className="text-red-600 font-bold		text-sm	">
                You have reached all attempt limits of this lesson. You can't
                attempt this lesson any more
              </span>
            </div>
          ) : (
            <button
              className={`bg-${
                partnerId || "default"
              }-primary hover:opacity-90 text-white font-semibold rounded-lg p-5 mt-6 w-full md:w-auto`}
              onClick={() => startNow()}
            >
              Attempt Now
            </button>
          )}
        </div>
      )}
    </Container>
  );
}
